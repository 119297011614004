import { gql } from '@ori/fetching';

import { productListingFacets } from './fragments';

export const searchResultsFacetsQuery = gql`
  ${productListingFacets}
  query SearchResultsFacets($query: String!, $facetFiltering: [PlpRowsFacetFilterInput!]) {
    application {
      productSearchPage {
        products(query: $query, topRows: 1, facetFiltering: $facetFiltering) {
          facets {
            ...ProductListingFacets
          }
        }
      }
    }
  }
`;
