import { gql } from '@ori/fetching';

import { orderByOptions } from './orderByOptions';
import { productListingFacets } from './productListingFacets';
import { productListingRow } from './productListingRow';

export const productListingRows = gql`
  ${productListingRow}
  ${orderByOptions}
  ${productListingFacets}
  fragment ProductListingRows on EditorialPlpRows {
    totalBannersCount
    totalProductsCount
    orderByOptions {
      ...OrderByOptions
    }
    facets {
      ...ProductListingFacets
    }
    rows(top: $limit, skip: $skip, orderBy: $orderBy, facetFiltering: $facetFiltering) {
      __typename
      ...ProductListingRow
    }
  }
`;
