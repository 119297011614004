import { gql } from '@ori/fetching';

import { productListingFacets } from './productListingFacets';

export const productListingFacetsRows = gql`
  ${productListingFacets}
  fragment ProductListingFacetsRows on EditorialPlpRows {
    facets {
      ...ProductListingFacets
    }
    rows(top: 1, facetFiltering: $facetFiltering) {
      content {
        ... on Product {
          productCode
        }
      }
    }
  }
`;
