import { gql } from '@ori/fetching';

export const productListingFacets = gql`
  fragment ProductListingFacets on PlpFacet {
    name
    active
    name
    displayName
    values {
      key
      count
      active
      displayName
      facetInfo {
        color
      }
    }
  }
`;
