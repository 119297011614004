/* istanbul ignore file -- internal use */
import { useToken } from '@ori/auth';
import { useServiceUrls } from '@ori/configuration-manager';
import { useEnvironment } from '@ori/environment';
import { useSWR } from '@ori/fetching';
import { useTranslationsWithNamespaces } from '@ori/i18n';
import { getHeaders } from '@ori/presentation-http';

import { getPreviewMode } from '../utils';
import { getEditorialCategoryOrPage } from './getEditorialCategoryOrPage';
import type { GetEditorialPreviewDataParams, UseGetEditorialCategoryOrPagePreviewData } from './types';

// Needed for preview mode used only in development and demo purposes.
const PREVIEW_DATA_APP_NAME = '@ori-appshell/products';

export const getPreviewData = async ({
  applicationName,
  customerId,
  graphQlUrl,
  language,
  pageId,
  previewMode,
  tenant,
  token,
}: GetEditorialPreviewDataParams) => {
  if (!previewMode) {
    return null;
  }
  const headers = getHeaders({
    sourceAppName: applicationName,
    language,
    tenant,
    token,
    customerId,
  });

  const editorialCategoryOrPageData = await getEditorialCategoryOrPage({
    graphQlUrl,
    headers,
    pageId,
    previewMode,
  });

  return editorialCategoryOrPageData;
};
/**
 * Used only in preview mode for development and demo purposes.
 *
 * Function calls fetch functions and returns data needed for editorial category or page preview enable by query param.
 */
export const useGetEditorialCategoryOrPagePreviewData = ({ pageId }: UseGetEditorialCategoryOrPagePreviewData) => {
  const { graphQlUrl } = useServiceUrls(['graphQl']);
  const previewMode = getPreviewMode();
  const { customerId, token } = useToken();
  const { language } = useTranslationsWithNamespaces();
  const { tenant } = useEnvironment();

  const { data } = useSWR(
    previewMode ? `editorialPageOrCategory/${pageId}` : null,
    async () =>
      getPreviewData({
        applicationName: PREVIEW_DATA_APP_NAME,
        customerId,
        graphQlUrl,
        language,
        pageId,
        previewMode,
        tenant,
        token,
      }),
    { dedupingInterval: 30000, revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false },
  );

  const { editorialLandingPage, editorialLandingPages = [], editorialPage } = data?.application ?? {};
  const categoryPreviewData = editorialLandingPage ? { editorialLandingPage, editorialLandingPages } : null;
  const pagePreviewData = editorialPage ? { editorialLandingPages, editorialPage } : null;

  return {
    loaded: !!data,
    previewMode,
    hasError: previewMode && !editorialPage,
    categoryPreviewData: previewMode ? categoryPreviewData : null,
    pagePreviewData: previewMode ? pagePreviewData : null,
  };
};
