import { gql } from '@ori/fetching';

import { editorialPageBannerFragment, editorialPageProductBoxCarouselFragment } from './editorialArticlePageFragment';
import { breadcrumbs, categoryBar, productListingCarousel, productListingRows } from './fragments';
import { productListingHeaderFragment } from './fragments/productListingHeader';

export const productListingQuery = gql`
  ${productListingRows}
  ${editorialPageBannerFragment}
  ${editorialPageProductBoxCarouselFragment}
  ${categoryBar}
  ${breadcrumbs}
  ${productListingCarousel}
  ${productListingHeaderFragment}

  query ProductListingPage(
    $pageId: ID!
    $categoryBarId: String!
    $breadcrumbsPageUrl: String!
    $channelType: EditorialChannelType!
    $limit: Int!
    $skip: Int
    $orderBy: PlpOrderBy = Recommended
    $facetFiltering: [EditorialPlpRowsFacetFilter] = []
  ) {
    application {
      breadcrumbLinks(pageUrl: $breadcrumbsPageUrl) {
        ...Breadcrumbs
      }
      pageNavigation(pageId: $categoryBarId) {
        ...CategoryBar
      }
      editorialPage(pageId: $pageId, channelType: $channelType) {
        pageId
        title
        thumbnailUrl
        contentItems {
          __typename
          ... on EditorialHeader {
            ...ProductListingHeader
          }
          ...ProductListingRows

          ... on EditorialBanner {
            ...EditorialPageBanner
          }

          ... on EditorialCarousel {
            ...ProductListingCarousel
          }

          ... on EditorialProductBoxCarousel {
            ...EditorialPageProductBoxCarousel
          }
          ... on EditorialOlapic {
            __typename
            product {
              productCode
            }
            categoryId
            type
          }
        }
      }
    }
  }
`;
