import { gql } from '@ori/fetching';

import { productListingRows } from './fragments';

export const productListingQueryPagination = gql`
  ${productListingRows}
  query ProductListingPagePagination(
    $pageId: ID!
    $channelType: EditorialChannelType!
    $limit: Int!
    $skip: Int
    $orderBy: PlpOrderBy = Recommended
    $facetFiltering: [EditorialPlpRowsFacetFilter] = []
  ) {
    application {
      editorialPage(pageId: $pageId, channelType: $channelType) {
        contentItems {
          __typename
          ...ProductListingRows
        }
      }
    }
  }
`;
