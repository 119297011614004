import { gql } from '@ori/fetching';

import { searchResultsProducts } from './fragments/searchResultsProducts';

export const searchResultsPageQuery = gql`
  ${searchResultsProducts}
  query SearchResultsPage(
    $query: String!
    $limit: Int!
    $skip: Int
    $orderBy: PlpOrderBy = Recommended
    $facetFiltering: [PlpRowsFacetFilterInput!]
  ) {
    application {
      productSearchPage {
        products(query: $query, topRows: $limit, skipRows: $skip, orderBy: $orderBy, facetFiltering: $facetFiltering) {
          ...SearchResultsProducts
        }
      }
    }
  }
`;
