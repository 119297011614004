import { gql } from '@ori/fetching';

export const categoryBar = gql`
  fragment CategoryBar on PageNavigation {
    __typename
    pageId
    items {
      __typename
      itemId
      label
      url
    }
  }
`;
