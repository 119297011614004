import { gql } from '@ori/fetching';
import { PRODUCT_WITH_SHADES_FRAGMENT } from '@ori/product-box';

import { orderByOptions } from './orderByOptions';
import { productListingFacets } from './productListingFacets';

export const searchResultsProductsRow = gql`
  ${PRODUCT_WITH_SHADES_FRAGMENT}
  fragment SearchProductRow on PlpRow {
    content {
      ...ProductWithShades
    }
  }
`;

export const searchResultsProducts = gql`
  ${searchResultsProductsRow}
  ${orderByOptions}
  ${productListingFacets}
  fragment SearchResultsProducts on ProductSearchPageProducts {
    totalProductsCount
    orderByOptions {
      ...OrderByOptions
    }
    facets {
      ...ProductListingFacets
    }
    rows {
      __typename
      ...SearchProductRow
    }
  }
`;
