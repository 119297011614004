import { gql } from '@ori/fetching';

export const promoBannerFragment = gql`
  fragment PromoBannerFragment on EditorialPlpPromoBanner {
    size
    banner {
      layout
      modules {
        link {
          title
          relativeUrl
        }
        backgroundVideo {
          __typename
          id
          playInLoop
        }
        backgroundImage {
          url
        }
        content {
          title
          titleColor
          verticalAlignment
        }
      }
    }
  }
`;
