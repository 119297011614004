import { Logger } from '@ori/logger';

import { APP_NAME } from './constants';

export const mainLogger = new Logger(APP_NAME);

export const mypagesMainLogger = new Logger(APP_NAME, {
  part: 'mypages',
});
export const catalogueMainLogger = new Logger(APP_NAME, {
  part: 'catalogues',
});
export const campaignsMainLogger = new Logger(APP_NAME, {
  part: 'campaigns',
});
export const personalProductListMainLogger = new Logger(APP_NAME, {
  part: 'personal-products-list',
});
export const ingredientDetailMainLogger = new Logger(APP_NAME, {
  part: 'ingredient-detail',
});
export const productListingMainLogger = new Logger(APP_NAME, {
  part: 'product-listing',
});
export const productDetailMainLogger = new Logger(APP_NAME, {
  part: 'product-detail',
});
export const brandsMainLogger = new Logger(APP_NAME, {
  part: 'brands',
});
export const landingMainLogger = new Logger(APP_NAME, {
  part: 'landing',
});
export const catalogueIPaperMainLogger = new Logger(APP_NAME, {
  part: 'catalogue-ipaper',
});
export const cataloguesLandingPageMainLogger = new Logger(APP_NAME, {
  part: 'catalogues-landing',
});
export const FavoritesListingMainLogger = new Logger(APP_NAME, {
  part: 'favorites-listing',
});
export const olapicPageMainLogger = new Logger(APP_NAME, {
  part: 'olapic',
});
export const myPagesPersonalProductsListsMainLogger = new Logger(APP_NAME, {
  part: 'mypages-personal-products-lists',
});
export const myPagesProductListMainLogger = new Logger(APP_NAME, {
  part: 'mypages-product-list',
});
export const newMyPagesPersonalProductsListsMainLogger = new Logger(APP_NAME, {
  part: 'new-mypages-personal-products-lists',
});
export const rewardsMainLogger = new Logger(APP_NAME, {
  part: 'rewards',
});
export const ingredientLibraryMainLogger = new Logger(APP_NAME, {
  part: 'ingredient-library',
});
export const searchResultsMainLogger = new Logger(APP_NAME, {
  part: 'search-results',
});
