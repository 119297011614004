import { gql } from '@ori/fetching';

export const productListingHeaderFragment = gql`
  fragment ProductListingHeader on EditorialHeader {
    id
    headline
    headlineStorybookColor
    extraLongHeadline
    backgroundStorybookColor
    imageUrl
    text
  }
`;
