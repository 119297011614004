import type { EditorialPageFragment } from '../models';

export const articles: EditorialPageFragment[] = [
  {
    __typename: 'EditorialPage',
    description: 'Description of Icons',
    pageId: '/campaigns/editorials/icons',
    title: 'Icons',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=28699c27-ca65-4e53-938e-757e0722f552&name=Bestseller-banner-gif_470x764&inputFormat=gif',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Introduction',
    pageId: '/campaigns/editorials/introduction',
    title: 'Introduction',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=c85fe5e7-208d-4e09-a181-38fe80f43605&name=novage-plus&inputFormat=png',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Blemish Age Defy Routine',
    pageId: '/campaigns/editorials/novage-plus-blemish-age-defy-routine',
    title: 'Blemish Age Defy Routine',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=a30c5c55-4aa4-4417-98be-f96dc3cd0165&name=Blemish-age-defy&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Bright Intense Routine',
    pageId: '/campaigns/editorials/novage-plus-bright-intense-routine',
    title: 'Bright Intense Routine',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=ee15dbbc-9d0e-48b1-8a82-1c5e26f13b45&name=BrightIntense_ThumbNail_200x200_X2&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Lift and Firm Routine',
    pageId: '/campaigns/editorials/novage-plus-lift-and-firm-routine',
    title: 'Lift and Firm Routine',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=0cf8327c-5d12-4abf-9969-6c177bb2061d&name=Novage-plus-routines2-1&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Loyalty Program',
    pageId: '/campaigns/editorials/novageplusloyaltyprogram',
    title: 'Loyalty Program',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=d49e266a-d718-4465-9244-4960258a8b92&name=LoyaltyProgram_ThumbNail_200x200_X2&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Novage Plus Routines',
    pageId: '/campaigns/editorials/novage-plus-routines',
    title: 'Novage Plus Routines',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=1142ef7a-5618-40d7-b774-adf7796b1259&name=Routine_ThumbNail_200x200_X2&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Skinfluencer',
    pageId: '/campaigns/editorials/novage-plus-skinfluencer',
    title: 'Skinfluencer',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=a0fb3a1d-764a-41fa-a01b-d769a8f99151&name=Skinfluencer_Option1_ThumbNail_200x200_X2&inputFormat=png',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description Wrinkle Smooth',
    pageId: '/campaigns/editorials/novage-plus-wrinkle-smooth-routine',
    title: 'Wrinkle Smooth',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=c5b127f9-86bf-4882-8297-2b9a6ae3e57b&name=WrinkleSmooth_ThumbNail_200x200_X2&inputFormat=jpg',
    themeTag: 'Editorials',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Test1',
    pageId: '/campaigns/editorials/testtheme/test1',
    title: 'Test1',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=6489c814-bb96-4dab-92e1-05b1d25c43db&name=skincareguidestartscreen&inputFormat=png',
    themeTag: 'Test Theme',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Test2',
    pageId: '/campaigns/editorials/testtheme/test2',
    title: 'Test2',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=a7189a19-744b-4842-a440-13d6555f1122&name=skincare-b2&inputFormat=jpg',
    themeTag: 'Test Theme',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Test3',
    pageId: '/campaigns/editorials/testtheme/test3',
    title: 'Test3',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=f23b1658-bb45-4dc5-8f9c-a9cc4ba0efe2&name=SkincareNovageDayShieldSpf50UvaPaSupremeCleansingGel3398434143&inputFormat=jpg',
    themeTag: 'Test Theme',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of 2-1',
    pageId: '/campaigns/editorials/testtheme2/test2-1',
    title: 'Test2-1',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=4ce72059-9bc3-42ca-ad5e-f54a34d6a2a1&name=giordanigoldpureuforiaappjpg&inputFormat=png',
    themeTag: 'TestTheme2',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description Test2-2',
    pageId: '/campaigns/editorials/testtheme2/test2-2',
    title: 'Test2-2',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=8d88c6ea-696f-464e-bbb3-d7254f29723e&name=giordani-gold-essenza-31816&inputFormat=png',
    themeTag: 'TestTheme2',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Test2-3',
    pageId: '/campaigns/editorials/testtheme2/test2-3',
    title: 'Test2-3',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=2c32f07c-bed5-4394-b1fb-d989b86d7ee2&name=giordanigold-relaunch-image-1x2_eyeshadowmodel&inputFormat=jpg',
    themeTag: 'TestTheme2',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Test3-1',
    pageId: '/campaigns/editorials/testtheme3/test3-1',
    title: 'Test3-1',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=0472a36a-883f-47d9-b67c-a1981a3edc45&name=Love+Nature_local+set+codes&inputFormat=png',
    themeTag: 'TestTheme3',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description Test3-2',
    pageId: '/campaigns/editorials/testtheme3/test3-2',
    title: 'Test3-2',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=bd106a60-6100-4e1f-a561-eb9e780f1530&name=giordanigold-relaunch-image-1x2-mascara&inputFormat=jpg',
    themeTag: 'TestTheme3',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Test3-3',
    pageId: '/campaigns/editorials/testtheme3/test3-3',
    title: 'Test3-3',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=5a1b8ada-3af8-46cf-bca2-e159824ac78f&name=Love+Nature+Forest+Berries+Delight+range_684x409&inputFormat=png',
    themeTag: 'TestTheme3',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Editorial Page Testing O',
    pageId: '/campaigns/editorials/testtheme4/editorialpagetestingolapic',
    title: 'Editorial Page Testing O',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=40f63086-1f59-436e-a8f6-6f0bdedffbb2&name=Baby-O-offer-top&inputFormat=jpg',
    themeTag: 'TestTheme4',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description Test4-1',
    pageId: '/campaigns/editorials/testtheme4/test4-1',
    title: 'Test4-1',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=0d19e6d6-7cd1-413a-a970-d516effa9621&name=Baby+O+photo+contest&inputFormat=jpg',
    themeTag: 'TestTheme4',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Veronika Editorial',
    pageId: '/campaigns/editorials/testtheme4/veronikaeditorial',
    title: 'Veronika Editorial',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=48ce8d25-92e3-4462-aaa0-128187cc653f&name=babyo-ar-app&inputFormat=png',
    themeTag: 'TestTheme4',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Wellosophy - Beauty from Within',
    pageId: '/campaigns/editorials/wellosophy/wellosophy-beauty-from-within',
    title: 'Wellosophy - Beauty from Within',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=0903acb4-bae0-4818-b029-775ab4f3940d&name=4_BeautyFromWithin&inputFormat=jpg',
    themeTag: 'Wellosophy',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Healthy Ageing',
    pageId: '/campaigns/editorials/wellosophy/wellosophy-healthy-ageing',
    title: 'Healty Ageing',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=546ac2f1-bd36-4000-836e-4d0ee0c137a7&name=5_HealthyAgeing&inputFormat=jpg',
    themeTag: 'Wellosophy',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Healthy Gut',
    pageId: '/campaigns/editorials/wellosophy/wellosophy-healthy-gut',
    title: 'Healthy Gut',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=12562f83-b3c1-46e7-98e2-6f9855fbbc41&name=6_HealthyGut&inputFormat=jpg',
    themeTag: 'Wellosophy',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Healthy Living',
    pageId: '/campaigns/editorials/wellosophy/wellosophy-healthy-living',
    title: 'Healthy Living',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=2b9cd312-fc05-4c8b-aeb3-a6582b842ef9&name=2_HealthyLiving&inputFormat=jpg',
    themeTag: 'Wellosophy',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of In Shape',
    pageId: '/campaigns/editorials/wellosophy/wellosophy-in-shape',
    title: 'In Shape',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=d7b8a492-0b8d-4e79-9c1d-3e0703ab9fe6&name=3_InShape&inputFormat=jpg',
    themeTag: 'Wellosophy',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of Wellosophy Subscription',
    pageId: '/campaigns/editorials/wellosophy/wellosophy-subscription',
    title: 'Wellosophy Subscription',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=89c15f0a-9339-4285-9eb0-8926df063e18&name=7_Subscription&inputFormat=jpg',
    themeTag: 'Wellosophy',
  },
  {
    __typename: 'EditorialPage',
    description: 'Description of True Wellbeing Starts Here',
    pageId: '/campaigns/editorials/wellosophy/wellosophy-true-wellbeing-starts-here',
    title: 'True Wellbeing Starts Here',
    thumbnailUrl:
      'https://media-uat-we-cdn.oriflame.com/contentImage?externalMediaId=89c15f0a-9339-4285-9eb0-8926df063e18&name=7_Subscription&inputFormat=jpg',
    themeTag: 'Wellosophy',
  },
];
