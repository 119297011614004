import { gql } from '@ori/fetching';

import { editorialCategoryPageFragment } from './editorialCategoryPageFragment';

export const editorialLandingPageQuery = gql`
  ${editorialCategoryPageFragment}
  query EditorialLandingPage($pageId: ID!) {
    application {
      ...EditorialCategoryPage
    }
  }
`;
