import { PREVIEW_MODE, fetchGraphql } from '@ori/fetching';

import type { EditorialCategoryOrPageQuery, EditorialCategoryOrPageQueryVariables } from '../models';
import { editorialCategoryOrPageQuery } from '../queries';
import { filterOutCurrentCategory } from '../utils';
import type { GetEditorialCategoryOfPageParams } from './types';

/**
 * Fetch function that fetches data for editorial category or landing page from GraphQL.
 */
export const getEditorialCategoryOrPage = async ({
  graphQlUrl,
  headers,
  pageId,
  previewMode,
}: GetEditorialCategoryOfPageParams): Promise<EditorialCategoryOrPageQuery> => {
  const data = await fetchGraphql<EditorialCategoryOrPageQuery, EditorialCategoryOrPageQueryVariables>({
    query: editorialCategoryOrPageQuery,
    url: graphQlUrl,
    variables: {
      pageId,
    },
    headers: {
      ...headers,
      [PREVIEW_MODE]: previewMode ?? false,
    },
  });
  const { editorialLandingPage, editorialLandingPages, editorialPage } = data.application ?? {};

  return {
    application: {
      editorialLandingPage,
      editorialLandingPages: filterOutCurrentCategory({ pageId, categories: editorialLandingPages }),
      editorialPage,
    },
  };
};
