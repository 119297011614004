import { gql } from '@ori/fetching';

export const breadcrumbs = gql`
  fragment Breadcrumbs on BreadcrumbLinks {
    links {
      relativeUrl
      title
    }
  }
`;
