import { gql } from '@ori/fetching';
import { PRODUCT_WITH_SHADES_FRAGMENT } from '@ori/product-box';

import { promoBannerFragment } from './promoBannerFragment';

export const productListingRow = gql`
  ${PRODUCT_WITH_SHADES_FRAGMENT}
  ${promoBannerFragment}
  fragment ProductListingRow on EditorialPlpRow {
    content {
      __typename
      ...ProductWithShades
      ...PromoBannerFragment
    }
  }
`;
