import { getTypedQueryParam } from '@ori/presentation-utils';
import type { IncomingMessage } from 'node:http';

export const PREVIEW_PARAM_NAME = 'previewEditorialPage';
export const getPreviewMode = (request?: IncomingMessage): boolean => {
  // Works with SSG, SSR and CSR
  const enable = getTypedQueryParam<boolean>({
    name: PREVIEW_PARAM_NAME,
    validate: (value: unknown) => {
      if (value === 'true') {
        return true;
      } else if (value === 'false') {
        return false;
      }

      return null;
    },
    req: request,
  });

  return enable ?? false;
};
