import { gql } from '@ori/fetching';

import { editorialPageFragment } from '../editorialCategoryPageFragment';

export const productListingCarouselItem = gql`
  ${editorialPageFragment}

  fragment ProductListingCarouselItem on EditorialPage {
    ...EditorialPage
  }
`;

export const productListingCarousel = gql`
  ${productListingCarouselItem}

  fragment ProductListingCarousel on EditorialCarousel {
    id
    editorials {
      ...ProductListingCarouselItem
    }
  }
`;
